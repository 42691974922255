import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const PublicationsList = () => {
  const [publications, setPublications] = useState([]);
  const [idSuppr, setIdSuppr] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (v) => {setShow(true); setIdSuppr(v)}

  useEffect(() => {
    axios.get('http://localhost:5000/publications')
      .then(response => {setPublications((response.data))})
      .catch(error => console.error('Error fetching publications:', error));
  }, []);

  const fetchPublications = () => {
    axios.get('http://localhost:5000/publications')
      .then(response => setPublications(response.data))
      .catch(error => console.error('Error fetching publications:', error));
  };

  const deletePublication = (id) => {
    axios.delete(`http://localhost:5000/publications/${id}`)
      .then(response => {
        fetchPublications();
        handleClose();
      })
      .catch(error => {console.error('Error deleting publication:', error);handleClose()});
  };

  return (
    <>
    <div className="container mt-5">
      <Row>
      <Col sm={10} className=""><h2>Publications</h2></Col>
      <Col sm={2} className="text-end">
              {/* <Link className="btn btn-primary btn-sm"  role="button" to="/publications_ajout"> 
                  Ajouter
              </Link> */}
              <Button className="btn-primary btn-sm" as={Link} to="/publications_ajout">Ajouter</Button>
      </Col>
      </Row>
    <Table bordered hover size="sm">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      {publications.map((publication) => (
         <tr  key={publication.id}>
          <td>{publication.id}</td>
          <td>{publication.title}</td>
          <td>
          <Button type="button" className="btn-success mt-2 mx-2" as={Link} to={`/publications_edit/${publication.id}`}>Editer</Button>
          <Button type="button" className="btn-danger mt-2 mx-2" onClick={() => handleShow(publication.id)}>Suppr</Button>
          </td>
        </tr>

            ))}
        
        </tbody>
    </Table>

    </div>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Supprimer un enregistrement !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez vous supprimer cet enregistrement</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="danger" onClick={() => deletePublication(idSuppr)}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublicationsList;
