import React, { useState, useEffect } from 'react';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AlertSaveOk from './AlertSaveOk';
import AlertSaveNotOk from './AlertSaveNotOk';

const PublicationsEdit = () => {
  const { id } = useParams();
  // const navigate = useNavigate();  // Pour rediriger l'utilisateur après la mise à jour
  const [publication, setPublication] = useState([]);
  const [alertOK, setAlertOK] = useState(false);
  const [alertNotOK, setAlertNotOK] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('http://localhost:5000/publications')
      .then(response => {
        const existingPublication = response.data.find(c => c.id === parseInt(id));
        if (existingPublication) {
          setPublication(existingPublication);         
        }
      })
      .catch(error => {
        setError(error);
      });
  }, [id]);

  const updatePublication = () => {
    console.log(publication);
    axios.put(`http://localhost:5000/publications/${publication.id}`, publication)
      .then(() => {
        setAlertOK(true);
        //navigate(`/publications_liste`);        
      })
      .catch(error => {
        console.error('Erreur ajout publication:', error);
        setAlertNotOK(true);
      });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    updatePublication();
  };

  return (
    <div className="container mt-5">
      { alertOK ? <AlertSaveOk /> : ''}
      { alertNotOK ? <AlertSaveNotOk /> : ''}

      <h2>Modifier Publication</h2>

      <div className="mt-3">
        <form onSubmit={handleSubmit}>
     
        <input
          type="text"
          className="form-control my-2"
          defaultValue={publication ? publication.id : null}
          onChange={(e) => setPublication({ ...publication, id: e.target.value })}
          readOnly
        />
        
        <input
          type="text"
          className="form-control my-2"
          defaultValue={publication ? publication.title : null}
          onChange={(e) => setPublication({ ...publication, title: e.target.value })}
          required
        />

          <button type="submit" className="btn btn-primary mt-2">Enregistrer</button>
          <Button type="button" className="btn-secondary mt-2 mx-2" as={Link} to="/publications_liste">Retour</Button>
        </form>
        
      </div>
    </div>
  );
};

export default PublicationsEdit;
