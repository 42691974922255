import "./App.css"
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PublicationsAjout from './PublicationsAjout';
import PublicationsEdit from './PublicationsEdit';
import PublicationsList from './PublicationsList';

export function App() {
    return (
      <Router>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container justify-content-start">
          <Link className="navbar-brand mx-2" to="/">Home</Link>
          <Link className="nav-link mx-2" to="/publications_liste">Liste_Publications</Link>
        </div>
      </nav>
  
      <div className="">
        <Routes>
            <Route path="/publications_liste" element={<PublicationsList />} />
            <Route path="/publications_ajout" element={<PublicationsAjout />} />
            <Route path="/publications_edit/:id" element={<PublicationsEdit />} />
        </Routes>
      </div>
    </Router>
    );
  };
  

  