import React, { useState, useEffect } from 'react';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AlertSaveOk from './AlertSaveOk';
import AlertSaveNotOk from './AlertSaveNotOk';

const PublicationsAjout = () => {
  const [publications, setPublications] = useState([]);
  const [newTitle, setNewTitle] = useState('');
  const [alertOK, setAlertOK] = useState(false);
  const [alertNotOK, setAlertNotOK] = useState(false);

  useEffect(() => {
    fetchPublications();
  }, []);

  const fetchPublications = () => {
    axios.get('http://localhost:5000/publications')
      .then(response => setPublications(response.data))
      .catch(error => console.error('Error fetching publications:', error));
  };

  const addPublication = () => {
    axios.post('http://localhost:5000/publications', { title: newTitle })
      .then(response => {
        fetchPublications();
        setNewTitle('');
        setAlertOK(true);
      })
      .catch(error => {
        console.error('Erreur ajout publication:', error);
        setAlertNotOK(true);
      });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
      addPublication();
  };

  return (
    <div className="container mt-5">
      { alertOK ? <AlertSaveOk /> : ''}
      { alertNotOK ? <AlertSaveNotOk /> : ''}

      <h2>Nouvelle Publication</h2>

      <div className="mt-3">
        <form onSubmit={handleSubmit}>
          <input 
            type="text" 
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
            className="form-control"
            placeholder="Publication title"
            required
          />
          <button type="submit" className="btn btn-primary mt-2">
            Ajouter
          </button>
          <Button type="button" className="btn-secondary mt-2 mx-2" as={Link} to="/publications_liste">Retour</Button>
        </form>
        
      </div>
    </div>
  );
};

export default PublicationsAjout;
